<template>
  <div class="page"></div>
</template>

<script>
export default {
  created() {
    const module = this.$route.query.module;
    switch (module) {
      // 个人中心
      case "grzx":
        this.$router.push("/Account/ModifyInfo");
        break;
      // 忘记密码
      case "wjmm":
        this.$router.push({ query: { module }, path: "/Account/SignIn" });
        break;
      default:
        break;
    }
  },
};
</script>